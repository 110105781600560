<!--<app-top-navbar class="admin-top-navbar show-bare-branding" mode="condensed"></app-top-navbar>-->

<!-- this looks good but the cc-image-shell causes infinate loop load, and the rest of page doesn't generate even when commenting that out
<mat-toolbar class="top-navbar-toolbar">
    <mat-toolbar-row  class="d-flex justify-content-center align-items-center">
        <span class="nvarbar-title">ROXIE</span>
        <div class="navbar-user">
            <div class="user-image-wrapper">
              <cc-aspect-ratio [ratio]="{w: 1, h: 1}">
                <cc-image-shell class="user-image" animation="spinner" [src]="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Ginger_Rogers_as_Roxie_Hart_1942.jpg/800px-Ginger_Rogers_as_Roxie_Hart_1942.jpg" [alt]="'user image'"></cc-image-shell>
              </cc-aspect-ratio>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
-->

<mat-sidenav-container>
    <mat-sidenav
      class="admin-main-sidenav fixed-sidenav clear-sidenav-background"
      #mainSidenav
      [mode]="mainSideMenuMode"
      [opened]="mainSidenavOpened"
      position="start"
      (openedStart)="openedStart()"
      (openedChange)="mainSidenavToggled($event)">
      <app-main-menu class="show-branding use-active-item-line-decoration"></app-main-menu>
    </mat-sidenav>
    <mat-sidenav-content class="admin-main-content">
      <app-top-navbar class="admin-top-navbar show-bare-branding" mode="condensed"></app-top-navbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    <mat-sidenav class="admin-alt-sidenav fixed-sidenav" #altSidenav mode="over" opened="false" position="end" (openedChange)="altSidenavToggled($event)">
      <app-dynamic-menu></app-dynamic-menu>
    </mat-sidenav>
  </mat-sidenav-container>
  


<!--
<ng-template #condensedToolbar>
    <mat-toolbar class="top-navbar-toolbar">
      <mat-toolbar-row>
        <div class="navbar-branding">
          <button class="btn toggle-sidenav-btn" type="button">
            <i class="fa fa-bars"></i>
          </button>
        </div>
        <span class="navbar-title">Roxie</span>
        <div class="navbar-user">
          <div class="user-image-wrapper">
            <cc-aspect-ratio [ratio]="{w: 1, h: 1}">
              <cc-image-shell class="user-image" animation="spinner" [src]="loggedUser?.image" [alt]="'user image'"></cc-image-shell>
            </cc-aspect-ratio>
          </div>
          <div ngbDropdown class="user-actions-dropdown" placement="bottom-right" display="static">
            <button class="user-action-toggle-btn btn btn-link" id="accountMenu" ngbDropdownToggle></button>
            <div ngbDropdownMenu aria-labelledby="accountMenu" class="dropdown-menu-right">
              <a class="btn btn-link" ngbDropdownItem [routerLink]="['/forms/form-layouts']">Account</a>
              <button class="btn btn-link" ngbDropdownItem>Log out</button>
            </div>
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <div class="navbar-controls">
          <div class="navbar-search">
            <input class="search-input form-control form-control-lg" type="text" placeholder="Search">
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </ng-template>
-->